import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout location={location}>
    <Helmet title="Remboursement" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>RETOURS</h1>

      <p>
        Notre politique dure 30 jours. Si 30 jours se sont écoulés depuis votre achat, nous ne pouvons malheureusement
        pas vous offrir un remboursement ou un échange. Pour pouvoir bénéficier d’un retour, votre article doit être
        inutilisé et dans le même état où vous l’avez reçu. Il doit être également dans l’emballage d’origine. Plusieurs
        types de produits ne peuvent pas être retournés. Les marchandises périssables telles que la nourriture, les
        fleurs ou les magazines ne peuvent pas être retournées. Nous n’acceptons pas non plus de produits à usage intime
        ou sanitaire, de produits dangereux ou de gaz ou de liquides inflammables. Autres articles qui ne peuvent pas
        être retournés: * Cartes cadeaux * Logiciels téléchargeables * Certains produits de santé et de soins personnels
        Pour effectuer un retour, vous devez nous présenter un reçu ou une preuve d’achat. Veuillez s’il vous plait ne
        pas renvoyer votre achat chez le fabricant. Il y a certaines situations où seul un remboursement partiel est
        accordé: (le cas échéant) * Livres avec des signes évidents d’utilisation. * CD, DVD, cassette VHS, logiciel,
        jeu vidéo, cassette, ou disque vinyle qui a été ouvert. * Tout article qui n’est pas dans son état d’origine,
        qui est endommagé ou qui présente certaines pièces manquantes pour des raisons qui ne sont pas dues à une erreur
        de notre part. * Tout article qui est retourné plus de 30 jours après la livraison.
      </p>

      <h3>Remboursements (le cas échéant)</h3>
      <p>
        Une fois que nous aurons reçu et inspecté l’article retourné, nous vous enverrons un e-mail pour vous confirmer
        que nous l’avons bien reçu. Nous vous informerons également de notre décision quant à l’approbation ou au rejet
        de votre demande de remboursement. Si votre demande est approuvée, alors votre remboursement sera traité, et un
        crédit sera automatiquement appliqué à votre carte de crédit ou à votre méthode originale de paiement, dans un
        délai d’un certain nombre de jours.
      </p>

      <h3>Remboursements en retard ou manquants (le cas échéant)</h3>
      <p>
        Si vous n’avez pas encore reçu votre remboursement, veuillez d’abord consulter votre compte bancaire à nouveau.
        Ensuite, contactez l’entité émettrice de votre carte de crédit, car il pourrait y avoir un délai avant que votre
        remboursement ne soit officiellement affiché. Ensuite, contactez votre banque. Il y a souvent un délai de
        traitement nécessaire avant qu’un remboursement ne soit affiché. Si après avoir effectué toutes ces étapes, vous
        n’avez toujours pas reçu votre remboursement, veuillez s’il vous plait nous contacter à picaud.marc@gmail.com.
      </p>

      <h3>Articles soldés (le cas échéant)</h3>
      <p>
        Seuls les articles à prix courant peuvent être remboursés. Malheureusement, les articles soldés ne sont pas
        remboursables.
      </p>

      <h3>Échanges (le cas échéant)</h3>
      <p>
        Nous remplaçons un article seulement s’il est défectueux ou endommagé. Si dans ce cas vous souhaitez l’échanger
        contre le même article, envoyez-nous un e-mail à picaud.marc@gmail.com et envoyez-nous votre article à: 37 rue
        Rennequin, Paris, J, 75017, France.
      </p>

      <h3>Cadeaux</h3>
      <p>
        Si l’article retourné était identifié comme un cadeau lors de l’achat et s’il vous a été envoyé directement,
        vous recevrez un crédit-cadeau d’une valeur égale à celle de votre retour. Une fois que nous aurons reçu
        l’article retourné, un chèque-cadeau vous sera envoyé par courrier. Si l’article n’a pas été identifié comme un
        cadeau lors de l’achat, ou si le donateur du cadeau a préféré recevoir d’abord l’article pour vous l’offrir plus
        tard, nous enverrons un remboursement au donateur du cadeau et il saura que vous avez retourné l’article.
      </p>

      <h3>Expédition</h3>
      <p>
        Pour retourner un produit, vous devez l’envoyer par la poste à: 37 rue Rennequin, Paris, J, 75017, France. Il
        vous incombera de payer vos propres frais d’expédition pour retourner votre article. Les coûts d’expédition ne
        sont pas remboursables. Si vous recevez un remboursement, les frais de retour seront déduits de celui-ci. En
        fonction de l’endroit où vous vivez, le délai nécessaire pour recevoir votre produit échangé peut varier. Si
        vous expédiez un article d’une valeur supérieure à $75, vous devriez envisager d’utiliser un service de
        livraison qui vous permet de suivre l’envoi ou de souscrire à une assurance de livraison. Nous ne garantissons
        pas que nous recevrons l’article que vous nous retournez.
      </p>
    </section>
  </Layout>
);
